import React, { useEffect, useState } from "react";
import isNullOrIsEmptyOrIsUndefined from "../utilities/CommonValidator";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { updateClassDTO, modelToggle } from "../actions/Action";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBFile,
} from "mdb-react-ui-kit";
import {
  createProduct,
  getAllProducts,
  updateProductDetails,
  updateToggleStatus,
} from "../service/Service";

import "./styles.css";

const Product = (props) => {

  // const toggleOpen = () => setBasicModal(!basicModal);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classDTO, isModel, productList } = props;
  const [showDescription, setShowDescription] = useState(false);

  var userToken = localStorage.getItem("token", dispatch);
  useEffect(() => {
    if (isNullOrIsEmptyOrIsUndefined(userToken)) {
      navigate("/vexon/home");
    }
    getAllProducts("/list", dispatch);
  }, [navigate, isModel]);

  const openModal = () => {
    dispatch(modelToggle(true));
  };

  const closeModal = () => {
    dispatch(modelToggle(false));

    // Array of element IDs to reset
    const elementIds = ["productName", "productDescription", "productImage"];

    // Reset the value of each element
    elementIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.value = "";
      }
    });
  };

  const handleSubmitDTO = () => {
    const finalDTO = {
      productName: classDTO.productName,
      productDescription: classDTO.productDescription,
      productImage: classDTO.productImage || "",
    };
    const formData = new FormData();
    formData.append("productDescription", finalDTO.productDescription);
    formData.append("productImage", finalDTO.productImage);
    formData.append("productName", finalDTO.productName);

    // if (classDTO.isEdit === true) {
    formData.append("productId", classDTO.productId);
    //   updateProductDetails("/updateProductDetails", dispatch, formData);
    // } else {
    createProduct("/saveProduct", dispatch, formData);
    // }
  };

  const handleClassDTO = (key, value) => {
    const updatedClassDTO = { ...classDTO, [key]: value };
    dispatch(updateClassDTO(updatedClassDTO));
  };

  const handleChangeToggleStatus = (toggleStatus, data) => {
    updateToggleStatus("/updateToggleStatus", data, dispatch);
  };

  const getProductDataForUpdate = (data) => {
    openModal();
    const updatedClassDTO = {
      productId: data.productId,
      productName: data.productName,
      productDescription: data.productDescription,
      productImageName: data.productImageName,
      isEdit: true,
    };
    dispatch(updateClassDTO(updatedClassDTO));
  };

  // if (isNullOrIsEmptyOrIsUndefined(userToken)) {
  //   navigate("/vexon/home");
  // }

  return (
    <div style={{ margin: "130px 5%", maxWidth: "90%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="cursive-text">Product Master</h3>
        <MDBBtn onClick={openModal}>Add Product</MDBBtn>
      </div>

      <table className="table align-middle mb-0 bg-white mt-2">
        <thead className="bg-light">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(productList || []).map((product, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    src={product.productImageName}
                    alt=""
                    style={{ width: "45px", height: "45px" }}
                    className="rounded-circle"
                  />
                  <div className="ms-3">
                    <p className="fw-bold mb-1">{product.productName}</p>
                  </div>
                </div>
              </td>
              <td>
                <div className="product-description">
                  <span
                    className="description-preview"
                    onMouseEnter={() =>
                      setShowDescription(product.productDescription.length > 29)
                    }
                    onMouseLeave={() => setShowDescription(false)}
                  >
                    {product.productDescription.slice(0, 30) || ""}{" "}
                    {product.productDescription.length > 30 && "..."}
                  </span>
                  {showDescription && (
                    <div className="description-popup">
                      <p>{product.productDescription}</p>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    onChange={(event) => {
                      handleChangeToggleStatus(event.target.checked, {
                        productId: product.productId,
                        status: product.status,
                      });
                    }}
                    checked={product.status === 1 ? true : false}
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckChecked"
                  ></label>
                </div>
                {/* <span className="badge badge-success rounded-pill d-inline">
                  Active
                </span> */}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-link btn-sm btn-rounded"
                  onClick={() => {
                    getProductDataForUpdate({
                      productId: product.productId,
                      productName: product.productName,
                      productDescription: product.productDescription,
                      productImageName: product.productImageName,
                    });
                  }}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <MDBModal open={isModel} tabIndex="-1">
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {classDTO.isEdit === true ? "Update Product" : "Add Product"}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={closeModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <MDBInput
                      label="Product Name"
                      id="productName"
                      type="text"
                      value={classDTO.productName}
                      onChange={(event) => {
                        handleClassDTO("productName", event.target.value);
                      }}
                    />
                  </div>
                  <div class="col-md-6">
                    <MDBFile
                      id="productImage"
                      onChange={(event) => {
                        const selectedFile = event.target.files[0];
                        if (selectedFile) {
                          handleClassDTO("productImage", selectedFile);
                        }
                      }}
                    />
                  </div>
                  {classDTO.productImageName ? (
                    <div class="col-md-1">
                      <img
                        src={classDTO.productImageName}
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                        className="rounded-circle"
                      />
                    </div>
                  ) : null}
                </div>
                <div class="row mt-3">
                  <div class="form-group purple-border">
                    {/* <label for="exampleFormControlTextarea4">Colorful border</label> */}
                    <textarea
                      class="form-control"
                      id="productDescription"
                      placeholder="Enter Product Description"
                      rows="3"
                      value={classDTO.productDescription}
                      onChange={(event, value) => {
                        handleClassDTO(
                          "productDescription",
                          event.target.value
                        );
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={closeModal}>
                Close
              </MDBBtn>
              <MDBBtn onClick={handleSubmitDTO}>
                {classDTO.isEdit === true ? "Update Product" : "Save Product"}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  classDTO: state.Reducer.classDTO,
  isModel: state.Reducer.isModel,
  productList: state.Reducer.productList,
});

export default connect(mapStateToProps)(Product);
