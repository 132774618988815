import logo from './logo.svg';
import './App.css';
import App from "./components/common/Header";
import './styles/tailwind.css'

function MainApp() {
  return (
    <div className="App">
      <App/>
    </div>
  );
}

export default MainApp;
