import React from "react";
import { Icon } from "@iconify/react";
import mapPinLine from "@iconify-icons/ri/map-pin-line";
import phoneLine from "@iconify-icons/ri/phone-line";
import mailSendLine from "@iconify-icons/ri/mail-send-line";

export default function Footer() {
  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Contact Us</h2>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="contact-about">
                <h3>Vexon</h3>
                <p>
                  At Vexon Electric, we illuminate the future of low-voltage
                  electrical solutions. Since our establishment in 2014, we've
                  been pioneers in delivering cutting-edge low-voltage
                  electrical products and solutions. With an unwavering
                  commitment to innovation, reliability, and sustainability, we
                  cater to diverse industry needs. From MCCBs to panel
                  accessories, our comprehensive range ensures seamless
                  integration and unparalleled performance in low-voltage
                  applications. Discover the power of excellence with Vexon
                  Electric – where quality meets reliability.
                </p>
                <div className="social-links">
                  <a href="#" className="twitter">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#" className="facebook">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#" className="instagram">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#" className="linkedin">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 mt-4 mt-md-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="info">
                <div>
                  <Icon className="infoIcon" icon={mapPinLine} />
                  <p>
                    WEL-CON CONTROL & SWITCHGEAR Shop No.3 Green Park Society
                    Rajosana, Ta Vadgam, Banaskantha Gujarat-385520
                  </p>
                </div>
                <div>
                  <Icon className="infoIcon" icon={mailSendLine} />
                  <p>vexonelectric14@gmail.com</p>
                </div>
                <div>
                  <Icon className="infoIcon" icon={phoneLine} />
                  <p>+918511044212</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-12"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 text-lg-left text-center">
              <div className="copyright">
                &copy; Copyright <strong>2024 Vexon Electric</strong>. All
                Rights Reserved
              </div>
              {/* <div className="credits">
                Designed by{" "}
                <a href="https://bootstrapmade.com/">BootstrapMade</a>
              </div> */}
            </div>

            <div className="col-lg-6">
              <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
                <a href="#intro" className="scrollto">
                  Home
                </a>
                <a href="#about" className="scrollto">
                  About
                </a>
                <a href="#">Privacy Policy</a>
                <a href="#">Terms of Use</a>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
