// index.js

import { combineReducers } from 'redux';
import Reducer from './Reducer';

const rootReducer = combineReducers({
    Reducer
});

export default rootReducer;
