import React, { useEffect } from "react";
import "../../assets/css/loginpagestyle.css";
import Home from "../pages/Home";
import closeImg from "../../assets/img/login/cross.gif";
import { useNavigate } from "react-router-dom";
import { updateLoginClassDTO } from "../actions/Action";
import { connect, useDispatch } from "react-redux";
import { AdminLogin } from "../service/Service";
import isNullOrIsEmptyOrIsUndefined from "../utilities/CommonValidator";

const Login = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/home");
  //   }
  // }, [isLoggedIn, navigate]);

  var userToken = localStorage.getItem("token");
  useEffect(() => {
    const modal = document.getElementById("modalLoginForm");
    if (modal) {
      // Show the modal when component mounts
      modal.classList.add("show");
      modal.style.display = "block"; // Ensure it's visible
    }
    if (!isNullOrIsEmptyOrIsUndefined(userToken)) {
      navigate("/vexon/home");
    }
  }, []);

  const handleClose = () => {
    navigate("/vexon/home");
  };

  const { loginClassDTO } = props;

  const handleClassDTO = (key, value) => {
    var loginClassDTO = { ...props.loginClassDTO };
    switch (key) {
      default:
        loginClassDTO[key] = value;
    }
    dispatch(updateLoginClassDTO(loginClassDTO));
  };

  const handleSubmitDTO = async () => {
    const finalDTO = {
      email: loginClassDTO.email,
      password: loginClassDTO.password,
    };
    await AdminLogin(dispatch, "login", finalDTO);
  };

  if (!isNullOrIsEmptyOrIsUndefined(userToken)) {
    navigate("/vexon/home");
  }
  return (
    <div className="wrapper">
      <Home></Home>
      <div
        className="modal fade show" // Add 'show' class here
        id="modalLoginForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title w-100 font-weight-bold">Sign in</h4>
              <img
                src={closeImg}
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                alt=""
                srcset=""
                onClick={handleClose}
              />
            </div>
            <div className="modal-body mx-3">
              <div className="md-form mb-5">
                <i className="fas fa-envelope prefix grey-text"></i>
                <input
                  type="email"
                  id="defaultForm-email"
                  value={loginClassDTO.email}
                  onChange={(event, value) => {
                    handleClassDTO("email", event.target.value);
                  }}
                  className="form-control validate"
                />
                <label
                  data-error="wrong"
                  data-success="right"
                  htmlFor="defaultForm-email"
                >
                  Your email
                </label>
              </div>

              <div className="md-form mb-4">
                <i className="fas fa-lock prefix grey-text"></i>
                <input
                  type="password"
                  id="defaultForm-pass"
                  className="form-control validate"
                  value={loginClassDTO.password}
                  onChange={(event, value) => {
                    handleClassDTO("password", event.target.value);
                  }}
                />
                <label
                  data-error="wrong"
                  data-success="right"
                  htmlFor="defaultForm-pass"
                >
                  Your password
                </label>
              </div>
              {!isNullOrIsEmptyOrIsUndefined(loginClassDTO.errorMessage) ? (
                <div>
                  <p>
                    <i style={{ color: "red" }}>{loginClassDTO.errorMessage}</i>
                  </p>
                </div>
              ) : null}
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button
                className="btn btn-default btn-success"
                onClick={handleSubmitDTO}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginClassDTO: state.Reducer.loginClassDTO,
});

export default connect(mapStateToProps)(Login);
