import { ActionTypes } from "../../components/constant/Constant";

export const updateClassDTO = (data) => {
  return {
    type: ActionTypes.UPDATE_CLASS_DTO,
    payload: { data },
  };
};

export const updateLoginClassDTO = (data) => {
  return {
    type: ActionTypes.UPDATE_LOGIN_CLASS_DTO,
    payload: { data },
  };
};

export const modelToggle = (data) => {
  return {
    type: ActionTypes.IS_MODEL,
    payload: { data },
  };
};