import React, { useEffect } from "react";
// import businessLogo from "../../assets/img/hero-img.png";
import businessLogo from "../../assets/img/home1.jpg";
// import serviceImag1 from "../../assets/img/more-services-1.jpg";
// import serviceImag2 from "../../assets/img/more-services-2.jpg";
// import serviceImag3 from "../../assets/img/more-services-3.jpg";
// import serviceImag4 from "../../assets/img/more-services-4.jpg";

//
import customer from "../../assets/img/iconimages/customer.png";
import expertise from "../../assets/img/iconimages/expertise.png";
import quality from "../../assets/img/iconimages/quality_asurence.png";
import sutainable_energy from "../../assets/img/iconimages/sustainable-energy.png";
import { useNavigate } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import Aos from "aos";
// import "../../assets/css/loginpagestyle.css";
// import { useHistory } from 'react-router-dom';
import "aos/dist/aos.css";
import { getAllEnabledProducts } from "../service/Service";

// import "./style.css";

const Home = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { products } = props;

  useEffect(() => {
    Aos.init({
      duration: 2000,
      easing: "ease",
    });

    getAllEnabledProducts("/list-enabled-products", dispatch);
  }, [navigate]);

  // }, []);

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1 data-aos="fade-up" data-aos-duration="1000">
                Elevate Your Electrical Solutions with Vexon Electrical
              </h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
                Empower Your Projects with Quality Components
              </h2>
              <div data-aos="fade-up" data-aos-delay="800">
                <a href="#about" className="btn-get-started scrollto">
                  Get Started
                </a>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <img src={businessLogo} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="150">
              <h2>Welcome to Wel-Con Control Switchgear</h2>
              <ul>
                <li>
                  <i class="ri-check-double-line"></i>
                  At Wel-Con Control Switchgear, we have been committed to
                  providing high-quality electrical products and solutions since
                  our inception in 2014. With a focus on innovation,
                  reliability, and customer satisfaction, we offer a
                  comprehensive range of electrical components and panel
                  accessories designed to meet the diverse needs of our clients
                  across various industries.
                </li>
                <li>
                  <i class="ri-check-double-line"></i> Partner with Wel-Con
                  Control Switchgear for all your electrical needs and
                  experience the difference quality, reliability, and expertise
                  can make in your projects.
                </li>
                <li>
                  <i class="ri-check-double-line"></i>
                  Contact us today to learn more about our products and
                  services, and let us help you power your success with reliable
                  electrical solutions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="more-services services" className="more-services services">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Services</h2>
            {/* <p>
              Magnam dolores commodi suscipit eius consequatur ex aliquid fug
            </p> */}
          </div>
          <div class="row row-cols-1 row-cols-md-2 g-4">
            {products
              ? (products || []).map((product) => (
                  <div class="col">
                    <div class="card">
                      <img
                        src={product.productImageName}
                        class="card-img-top"
                        alt="Hollywood Sign on The Hill"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      />
                      <div class="card-body">
                        <h5 class="card-title">{product.productName}</h5>
                        <p class="card-text">
                          {product.productDescription.slice(0, 200) || ""}{" "}
                          {product.productDescription.length > 200 && "..."}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>

          {/* <div className="row">
            {products
              ? (products || []).map((product) => (
                  <div
                    className="col-md-6 d-flex align-items-stretch"
                    key={product.productId}
                  >
                    <div
                      className="card"
                      style={{
                        background: `url('${product.productImageName}')`,
                      }}
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="card-body">
                        <h5 className="card-title">
                          <a href="">{product.productName}</a>
                        </h5>
                        <p className="card-text">
                          {product.productDescription.slice(0, 150) || ""}{" "}
                          {product.productDescription.length > 150 && "..."}
                        </p>
                        <div className="read-more">
                          <a href="#">
                            <i className="bi bi-arrow-right"></i> Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div> */}
        </div>
      </section>

      <section id="testimonials" className="testimonials section-bg">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Why Choose Vexon Electric?</h2>
            <p>
              Discover the difference with Vexon – where innovation meets
              reliability in every solution we offer. Explore our comprehensive
              range of products and services designed to power your success.
            </p>
          </div>

          <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <div className="swiper-wrapper"> */}
            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img src={quality} className="testimonial-img" alt="" />
                  <h3>Quality Assurance</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    We adhere to stringent quality standards to ensure that our
                    products meet the highest quality and performance
                    benchmarks, delivering reliability and durability in every
                    application.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img src={expertise} className="testimonial-img" alt="" />
                  <h3>Industry Expertise</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    With years of industry experience, our team of experts is
                    dedicated to providing personalized support and guidance,
                    helping you find the right solutions for your specific needs
                    and challenges.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img src={customer} className="testimonial-img" alt="" />
                  <h3>Customer-Centric Approach</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    We prioritize customer satisfaction and strive to exceed
                    expectations through exceptional product quality, prompt
                    delivery, and responsive customer service.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <img
                    src={sutainable_energy}
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>Innovation and Sustainability</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Committed to innovation and sustainability, we invest in
                    research and development to offer innovative solutions that
                    minimize environmental impact and maximize energy
                    efficiency.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.Reducer.products,
});

export default connect(mapStateToProps)(Home);
