import { toast } from "react-toastify";
import { ActionTypes } from "../constant/Constant";
import config from "../../store/config";

const API_BASE_URL = `${config.baseUrl}/api/admin`;
const API_BASE_URL_PRODUCT = `${config.baseUrl}/api/product`;

export const AdminLogin = async (dispatch, endPointUrl, requestData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/${endPointUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseAuthentication = await response.json();
    if (responseAuthentication.success === 1) {
      toast.success(responseAuthentication.message);
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: {
          token: responseAuthentication.token,
        },
      });
    } else {
      // toast.error(responseAuthentication.message);
      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: {
          token: "",
          message: responseAuthentication.message,
        },
      });
    }
  } catch (error) {
    toast.error("Error:", error.message);
  }
};

export const createProduct = async (endPointUrl, dispatch, requestData) => {
  var userToken = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${userToken}`,
    // "Content-Type": "application/json",
  };
  try {
    const response = await fetch(`${API_BASE_URL_PRODUCT}${endPointUrl}`, {
      method: "POST",
      headers: headers,
      body: requestData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.success === 1) {
      toast.success(responseData.message);
      dispatch({
        type: ActionTypes.CREATE_PRODUCT_SUCCESS,
      });
    } else {
      toast.error(responseData.message || "Failed to create product");
    }

    return responseData;
  } catch (error) {
    toast.error("Error:", error.message);
  }
};

export const getAllProducts = async (endPointUrl, dispatch) => {
  var userToken = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  try {
    const response = await fetch(`${API_BASE_URL_PRODUCT}${endPointUrl}`, {
      method: "GET",
      headers: headers,
    });

    const responseData = await response.json();

    if (responseData.success === 1) {
      dispatch({
        type: ActionTypes.PRODUCT_LIST_RESPONSE,
        payload: {
          data: responseData.result,
        },
      });
    } else {
      if (
        responseData.success === 0 &&
        responseData.message === "Invalid token"
      ) {
        localStorage.removeItem("token");
        dispatch({
          type: ActionTypes.PRODUCT_LIST_RESPONSE,
          payload: {
            data: [],
          },
        });
      }
      toast.error(responseData.message || "Failed to get products");
    }
  } catch (error) {
    toast.error("An error occurred while processing your request"); // Display toast message
  }
};

export const updateToggleStatus = async (endPointUrl, data, dispatch) => {
  var userToken = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
  try {
    const response = await fetch(`${API_BASE_URL_PRODUCT}${endPointUrl}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (responseData.success === 1) {
      toast.success(responseData.message);
      getAllProducts("/list", dispatch);
    } else {
      toast.error(responseData.message || "Failed to update product toggle");
    }
  } catch (error) {
    toast.error("An error occurred while processing your request"); // Display toast message
  }
};

export const updateProductDetails = async (
  endPointUrl,
  dispatch,
  requestData
) => {
  var userToken = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${userToken}`,
    // "Content-Type": "multipart/form-data",
  };
  try {
    const response = await fetch(`${API_BASE_URL_PRODUCT}${endPointUrl}`, {
      method: "POST",
      headers: headers,
      body: requestData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();

    if (responseData.success === 1) {
      toast.success(responseData.message);
      dispatch({
        type: ActionTypes.CREATE_PRODUCT_SUCCESS,
      });
    } else {
      toast.error(responseData.message || "Failed to create product");
    }

    return responseData;
  } catch (error) {
    toast.error("Error:", error.message);
  }
};

export const getAllEnabledProducts = async (endPointUrl, dispatch) => {
  try {
    const response = await fetch(`${API_BASE_URL_PRODUCT}${endPointUrl}`, {
      method: "GET",
    });

    const responseData = await response.json();

    if (responseData.success === 1) {
      dispatch({
        type: ActionTypes.PRODUCT_LIST_RESPONSE_FOR_HOMEPAGE,
        payload: {
          data: responseData.result,
        },
      });
    } else {
      toast.error(responseData.message || "Failed to get products");
    }
  } catch (error) {
    toast.error("An error occurred while processing your request"); // Display toast message
  }
};
