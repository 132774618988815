import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainApp from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// import './assets/vendor/aos/aos.css';
// import './assets/vendor/bootstrap/css/bootstrap.min.css';
// import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
// import './assets/vendor/boxicons/css/boxicons.min.css';
// import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/swiper/swiper-bundle.min.css';

import '../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css'

import 'mdb-ui-kit/css/mdb.min.css';
import './assets/css/style.css'

import 'boxicons/css/boxicons.min.css';

// import 'swiper/swiper-bundle.css';

// import 'swiper/swiper-bundle.min.css';

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Pacifico', 'cursive'], // Replace 'Pacifico' with the name of the cursive font you want to use
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
