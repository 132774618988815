import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import isNullOrIsEmptyOrIsUndefined from "../utilities/CommonValidator";
import Product from "../adminpages/Product";
import PowerImage from "../../assets/img/iconimages/power.gif";

import "../../assets/css/style.css";
import "../../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../../assets/js/main";

export default function Header() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const currentPath = window.location.pathname;

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  useEffect(() => {
    // Check if the current path is /vexon/login and show the modal accordingly
    if (currentPath === "/vexon/login") {
      setShowModal(true);
    }

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    const handleMobileNavToggle = () => {
      const navbar = select("#custom-navbar");
      navbar.classList.toggle("custom-navbar-mobile");
      const mobileNavToggle = select(".custom-mobile-nav-toggle");
      mobileNavToggle.classList.toggle("bi-list");
      mobileNavToggle.classList.toggle("bi-x");
    };

    const handleNavItemClick = () => {
      const navbar = select("#custom-navbar");
      navbar.classList.remove("custom-navbar-mobile");
      const mobileNavToggle = select(".custom-mobile-nav-toggle");
      mobileNavToggle.classList.remove("bi-x");
      mobileNavToggle.classList.add("bi-list");
    };

    window.addEventListener("scroll", handleScroll);

    document.addEventListener("click", (event) => {
      if (event.target.classList.contains("custom-mobile-nav-toggle")) {
        handleMobileNavToggle();
      } else if (event.target.closest("#custom-navbar ul")) {
        handleNavItemClick();
      }
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleMobileNavToggle);
      document.removeEventListener("click", handleNavItemClick);
    };
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token from local storage
    toast.success("you are successfully logout!!");
    navigate("/vexon/home");
  };

  var userToken = localStorage.getItem("token");

  return (
    <>
      <body>
        <header
          id="header"
          className={`fixed-top d-flex align-items-center ${
            isScrolled ? "header-scrolled" : ""
          }`}
        >
          <div class="container d-flex align-items-center justify-content-between">
            <ToastContainer position="top-center" />
            <div className="container d-flex align-items-center justify-content-between">
              <div className="logo">
                <h1>
                  <a href="index.html">
                    <span style={{ color: "#3333cc" }}>VEXON</span>
                    <br />
                    <span style={{ fontSize: "20px", display: "flex" }}>
                      <span style={{ fontSize: "20px" }}>ELECTR</span>
                      <img
                        src={PowerImage}
                        style={{ height: "20px", verticalAlign: "middle" }}
                        alt="Power"
                      />
                      C
                    </span>
                  </a>
                </h1>
              </div>

              <nav id="custom-navbar" className="custom-navbar">
                <ul>
                  <li>
                    <Link
                      className={`nav-link scrollto ${
                        currentPath === "/vexon/home" ? "active" : ""
                      }`}
                      to="/vexon/home"
                    >
                      Home
                    </Link>
                  </li>
                  {!isNullOrIsEmptyOrIsUndefined(userToken) ? (
                    <li>
                      <Link
                        className={`nav-link scrollto ${
                          currentPath === "/vexon/product" ? "active" : ""
                        }`}
                        to="/vexon/product"
                      >
                        Product
                      </Link>
                    </li>
                  ) : null}
                  <li>
                    <a className="nav-link scrollto" href="#about">
                      About
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollto" href="#services">
                      Services
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scrollto" href="#contact">
                      Contact
                    </a>
                  </li>
                  {!isNullOrIsEmptyOrIsUndefined(userToken) ? (
                    <li>
                      <a
                        className="getstarted scrollto"
                        href="#about"
                        onClick={handleLogout}
                      >
                        Logout
                      </a>
                    </li>
                  ) : null}
                </ul>
                <i className="bi bi-list custom-mobile-nav-toggle"></i>
              </nav>
            </div>
          </div>
        </header>
        <Routes>
          <Route path="/vexon/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/vexon" element={<Home />} />
          <Route path="/vexon/product" element={<Product />} />
          <Route
            path="/vexon/login"
            element={<Login closeModal={closeModal} />}
          />
        </Routes>
        <Footer></Footer>
      </body>
    </>
  );
}
