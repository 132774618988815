import keyMirrorRecursive from "fbjs/lib/keyMirrorRecursive";
export const ActionTypes = keyMirrorRecursive(
    {
        LOGIN_SUCCESS:undefined,
        UPDATE_CLASS_DTO:undefined,
        UPDATE_LOGIN_CLASS_DTO:undefined,
        UPDATE_PRODUCT_CLASS_DTO:undefined,
        LOGOUT:undefined,
        CREATE_PRODUCT_SUCCESS:undefined,
        IS_MODEL:undefined,
        PRODUCT_LIST_RESPONSE:undefined,
        PRODUCT_LIST_RESPONSE_FOR_HOMEPAGE:undefined,
    },
)