import { ActionTypes } from "../components/constant/Constant";
// import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import isNullOrIsEmptyOrIsUndefined from "../components/utilities/CommonValidator";
import config from "../store/config";

const defaultClassDTO = {};
const initialState = {
  classDTO: { defaultClassDTO },
  productClassDTO: { defaultClassDTO },
  loginClassDTO: { defaultClassDTO },
  isLoggedIn: !!localStorage.getItem("token"),
  isModel: false,
};

const Reducer = (stateDTO = initialState, action) => {
  let state = _.cloneDeep(stateDTO);

  // const navigate = useNavigate();

  switch (action.type) {
    case ActionTypes.UPDATE_CLASS_DTO: {
      state.classDTO = action.payload.data || {};
      return { ...state };
    }

    case ActionTypes.UPDATE_PRODUCT_CLASS_DTO: {
      state.productClassDTO = action.payload.data || {};
      return { ...state };
    }

    case ActionTypes.UPDATE_LOGIN_CLASS_DTO: {
      state.loginClassDTO = action.payload.data || {};
      return { ...state };
    }

    case ActionTypes.LOGIN_SUCCESS: {
      var authData = action.payload.token || {};
      var errorMessage = action.payload.message || "";
      if (!isNullOrIsEmptyOrIsUndefined(errorMessage)) {
        state.loginClassDTO.errorMessage = errorMessage;
      } else {
        localStorage.setItem("token", authData);
        state.loginClassDTO = defaultClassDTO;
        state.loginClassDTO.errorMessage = "";
      }
      return { ...state };
    }

    case ActionTypes.LOGOUT: {
      localStorage.removeItem("token");
      toast.success("you are successfully logout!!");
      return {
        ...state,
        isLoggedIn: false,
      };
    }

    case ActionTypes.IS_MODEL: {
      const flag = action.payload.data || false;
      if (!flag) {
        state.classDTO = {}; // Reset classDTO to an empty object
      }
      state.isModel = flag;
      return { ...state };
    }

    case ActionTypes.CREATE_PRODUCT_SUCCESS: {
      state.isModel = false;
      state.classDTO = defaultClassDTO;
      return { ...state };
    }

    case ActionTypes.PRODUCT_LIST_RESPONSE: {
      var data = action.payload.data || false;
      var productList = [];
      (data || []).map((product) => {
        // Convert backslashes to forward slashes in the product image name
        const productImageName = product.productImageName.replace(/\\/g, "/");

        // Concatenate the base URL with the product image name
        const imageUrl = `${config.baseUrl}/${productImageName}`;

        productList.push({
          productId: product.productId,
          productName: product.productName,
          productDescription: product.productDescription,
          productImageName: imageUrl,
          status: product.status,
          createdDate: product.createdDate,
        });
      });
      state.productList = productList;
      return { ...state };
    }

    case ActionTypes.PRODUCT_LIST_RESPONSE_FOR_HOMEPAGE: {
      var data = action.payload.data || false;
      var products = [];
      (data || []).map((product) => {
        // Convert backslashes to forward slashes in the product image name
        const productImageName = product.productImageName.replace(/\\/g, "/");

        // Concatenate the base URL with the product image name
        const imageUrl = `${config.baseUrl}/${productImageName}`;

        // Push the product data with the concatenated image URL into the products array
        products.push({
          productId: product.productId,
          productName: product.productName,
          productDescription: product.productDescription,
          productImageName: imageUrl,
          status: product.status,
          createdDate: product.createdDate,
        });
      });

      state.products = products;
      return { ...state };
    }

    default:
      return state;
  }
};

export default Reducer;
